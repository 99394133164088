import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
// import store from "../store/index.js"

Vue.use(VueRouter)



const routes = [
  
  //登录页面
  {
    path:"/login",
    name:"login",
    component:()=>import("../views/pages/login.vue")
  },
  {
    path: '/',
    name: 'layout',
    component: ()=>import("../views/pages/layout.vue"),
    redirect:"/index",
    children:[
      {
        path:"/index",
        name:"index",
        component:()=>import("../views/pages/index.vue")
      }
      ,
      {
        path:"/user",
        name:"user",
        component:()=>import("../views/pages/user.vue")
      },
      {
        path:"/customer",
        name:"customer",
        component:()=>import("../views/pages/customer.vue")
      },
      {
        path:"/recharge",
        name:"recharge",
        component:()=>import("../views/pages/recharge.vue")
      },
      
      {
        path:"/order",
        name:"order",
        component:()=>import("../views/pages/order.vue")
      },
      {
        path:"/shopOrder",
        name:"shopOrder",
        component:()=>import("../views/pages/shopOrder.vue")
      },
      {
        path:"/dispatch",
        name:"dispatch",
        component:()=>import("../views/pages/dispatch.vue")
      },
      {
        path:"/receive",
        name:"receive",
        component:()=>import("../views/pages/receive.vue")
      }
    ]
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import( '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to,from,next)=>{
  /**
   * to 从哪个页面来
   * from 到哪个页面去
   * next：只有执行next()才会进行跳转
   */
  // 判断用户是否登录
 const userInfo = JSON.parse(localStorage.getItem("userInfo"))
  // const userInfo = store.state.userInfo.userInfo
  console.log(userInfo)
  if(!userInfo){
    // 未登录，跳转到登录页面
    if(to.path==="/login"){
      next()
      return
    }
    next("/login")
  }else{
    next()
  }
  
})
export default router
