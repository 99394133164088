
import Vue from 'vue'
import Vuex from 'vuex'
import number from "./state/num.state.js"
import userInfo from "./state/userInfo.state.js"

Vue.use(Vuex)

export default new Vuex.Store({
 
  // 数据比较多，分模块
  modules: {
    number:number,
    userInfo:userInfo
  }
})
